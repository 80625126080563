import React from "react"
import { graphql } from 'gatsby'

import PaddedContent from "components/paddedContent"

import styles from "styles/sections/text-section.module.scss"

const TextSection = ({ markdown }) => {
  return (
    <section>
      <PaddedContent>
        <div className={styles.container}>
          {markdown && (
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: markdown.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default TextSection

export const textSection = graphql`
  fragment TextSection on ContentfulTextSection {
    id
    markdown {
      childMarkdownRemark {
        html
      }
    }
  }
`
